// import React, { useState, useEffect } from 'react';
// import './NetworkStatusChecker.css'; // Import your CSS file

// const NetworkStatusChecker = () => {
//   const [showPopup, setShowPopup] = useState('none');

//   useEffect(() => {
//     const handleConnectionChange = () => {
//       if (!navigator.onLine) {
//         setShowPopup('block');
//         setTimeout(() => {
//           setShowPopup('none');
//         }, 3000); // Display for 3 seconds
//       }
//     };

//     window.addEventListener('offline', handleConnectionChange);
//     window.addEventListener('online', handleConnectionChange);

//     return () => {
//       window.removeEventListener('offline', handleConnectionChange);
//       window.removeEventListener('online', handleConnectionChange);
//     };
//   }, []);

//   return (
//     <div className={`z-20 popup`} style={{display: showPopup}}>
//       No internet connection!
//     </div>
//   );
// };

// export default NetworkStatusChecker;

import React, { useState, useEffect } from "react";
import './NetworkStatusChecker.css'; // Import your CSS file

const NetworkStatusChecker = () => {
  const [message, setMessage] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    const handleConnectionChange = () => {
      if (!navigator.onLine) {
        setMessage("No internet connection!");
        setIsPopupVisible(true);
      } else {

        const connection = navigator.connection;
        if (connection.effectiveType === "2g" || connection.effectiveType === "slow-2g" || connection.effectiveType === "3g" || connection.effectiveType === "slow-4g" || connection.downlink < 1) {
           setMessage("Your connection is slow. Features may load slowly!");
           setIsPopupVisible(true);
        }
        else {
          setMessage("You are back online!");
          setIsPopupVisible(true);
        }
      
        // Automatically dismiss the "back online" message after 3 seconds
        setTimeout(() => setIsPopupVisible(false), 4000);
      }
    };

    window.addEventListener("offline", handleConnectionChange);
    window.addEventListener("online", handleConnectionChange);

    return () => {
      window.removeEventListener("offline", handleConnectionChange);
      window.removeEventListener("online", handleConnectionChange);
    };
  }, []);

  const handleDismiss = () => {
    setIsPopupVisible(false);
  };

  // z-20 fixed top-0 left-0 w-full py-2 px-4 shadow-lg transition-opacity duration-300

  // z-20 fixed top-4 left-1/2 transform -translate-x-1/2 py-2 px-4 rounded shadow-lg transition-opacity duration-300

  return (
    <div
      className={`z-20 fixed top-0 left-0 w-full py-2 px-4 shadow-lg transition-opacity duration-300 ${
        isPopupVisible ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"
      } ${message === "No internet connection!" ? "bg-red-600 text-white" : "bg-green-600 text-white"}`}
    >
      <div className="flex items-center justify-between">
        <span>{message}</span>
        {message === "No internet connection!" && (
          <button
            onClick={handleDismiss}
            className="ml-4 bg-white text-red-600 font-bold py-1 px-3 rounded hover:bg-gray-200"
          >
            Okay
          </button>
        )}
      </div>
    </div>
  );
};

export default NetworkStatusChecker;
