import React, {useState} from 'react';
import { NavLink, Link } from 'react-router-dom';
import { RiHomeFill } from 'react-icons/ri';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import logo from '../assets/gospel-hymn.png';
import { categories } from '../utils/data';
import { BiUserPin } from 'react-icons/bi';
import { PiChurchDuotone } from 'react-icons/pi';
import { MdOutlineFavorite } from 'react-icons/md';
import { SiMattermost } from 'react-icons/si';
import { FcAndroidOs } from 'react-icons/fc';
import { FaAppStoreIos } from 'react-icons/fa';
import Navbar from "./Navbar"

const isNotActiveStyle = 'flex items-center px-5 gap-3 text-gray-500 hover:text-black transition-all duration-200 ease-in-out capitalize';
const isActiveStyle = 'flex items-center px-5 gap-3 font-extrabold border-r-2 border-black  transition-all duration-200 ease-in-out capitalize';

const Sidebar = ({ closeToggle, setShowAboutDialog, openAboutDialog}) => {
  const [searchTerm, setSearchTerm] = useState('');


  const handleCloseSidebar = () => {
    if (closeToggle) closeToggle(false);
  };

  const aboutPage = () => {
    setShowAboutDialog(!!true)
  }

  const openAppStore = () => {
    window.open('https://apps.apple.com/us/app/gospel-hymn-book-audio/id1497999278', '_blank');
  };

  const openGooglePlay = () => {
    window.open('https://play.google.com/store/apps/details?id=com.adediranife.cachymn', '_blank');
  };

  return (
    <div className="flex flex-col justify-between h-full overflow-y-scroll min-w-220 hide-scrollbar"
      style={{backgroundColor: 'rgb(255 248 249)'}}
    >
      <div className="flex flex-col">
        <Link
          to="/"
          className="flex px-5 gap-2 mb-9 mt-5 pt-1 w-190 items-center"
          onClick={handleCloseSidebar}
        >
          <div className="flex flex-row items-center">
            <img className='mx' src={logo} width={50} alt="logo"  /> 
            <p className='mx-2 font-bold text-md'>GospelHymnBook</p>
        </div>
        </Link>
        <div className="flex flex-col gap-5">

          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? isActiveStyle : isNotActiveStyle)}
            onClick={handleCloseSidebar}
          >
            <RiHomeFill />
            Home
          </NavLink>
          <h3 className="mt-2 px-5 text-base 2xl:text-xl">Explore</h3>
          {categories.slice(0, categories.length).map((category) => (
            <NavLink
              to={`/category/${category.path}`}
              className={({ isActive }) => (isActive ? isActiveStyle : isNotActiveStyle)}
              onClick={handleCloseSidebar}
              key={category.path}
            >
              {category.name.includes("Author") ? (
                 <BiUserPin className="w-8 h-8 rounded-full shadow-sm" />
              ): null}
              {category.name.includes("Gospel") ? (
                 <PiChurchDuotone className="w-8 h-8 rounded-full shadow-sm" />
              ): null}
              {category.name.includes("Favor") ? (
                 <MdOutlineFavorite className="w-8 h-8 rounded-full shadow-sm" />
              ): null}
              {!category.name.includes("About") && category.name}
            </NavLink>
            
          ))}

          {/* <NavLink
            onClick={openAboutDialog}
            className={({ isActive }) => (isActive ? isNotActiveStyle : isNotActiveStyle)}
            key="about-us">
             <GoOrganization className="w-8 h-8 rounded-full shadow-sm" /> About Us
          </NavLink> */}

          <div className="-mt-10">
            <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>
          </div>

          {/* <NavLink
          to={`/privacy`}
          className="flex my-5 mb-3 gap-2 w-25 p-2 items-center rounded-lg shadow-lg mx-3"
        > 
           <SiMattermost className="w-8 h-4 rounded-full shadow-sm" />
           <p className='text-gray-500 mx-2'>Terms of Use</p>
        </NavLink>  */}

        {/* <h3 className="px-5 text-base 2xl:text-xl">Available on Android and iOS</h3>
        <div className="flex gap-5 mx-5">
          <FcAndroidOs onClick={openGooglePlay} className="w-9 h-8 rounded-full shadow-sm" />
          <FaAppStoreIos onClick={openAppStore} className="w-8 h-8 rounded-full shadow-sm" />
        </div>  */}

          <NavLink
          to={`/privacy`}
          className="flex my-3 mb-3 gap-2 w-25 p-2 items-center rounded-lg shadow-sm mx-3"
        > 
           <SiMattermost className="w-8 h-4 rounded-full shadow-sm" />
           <p className='text-gray-500 mx-2'>Terms of Use</p>
        </NavLink> 

        </div>
      </div>
      
        {/* <Link
          to={`privacy/`}
          className="flex my-5 mb-3 gap-2 w-25 p-2 items-center bg-white rounded-lg shadow-lg mx-3"
        > <p className='text-gray-500 mx-2'>Terms of Use</p>
        </Link>  */}
    </div>
  );
};

export default Sidebar;