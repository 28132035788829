import React, {memo, useMemo, useEffect} from 'react'
import {
    Location,
    NavigationType,
    useLocation,
    useNavigate,
    useNavigationType,
  } from "react-router-dom";
  import LazyLoad from 'react-lazyload';
//   import searchIcon from '../assets/pageview_fill.svg'
import { RiSearchEyeLine } from "react-icons/ri";

const DialogHymn = ({version, versionHeader, hymnDb, flavor, backgroundRefresh}) => {

    const location = useLocation()
    const navigate = useNavigate();
    const navType = useNavigationType();

    const viewHymn = (key, tname, verse, category, flavor) => {
        const data = { 
            key,
            tname,
            verse,
            category,
            flavor,
            navpage: 1
        };
        navigate('/view-hymn', { 
            state: data
        })
    }

    const viewAuthor = (author, description, flavor) => {
        const data = { 
            author,
            description,
            flavor,
            navpage: 1
        };
        navigate('/articles', { 
            state: data
        })
    }

    const viewGospel = (title, message, flavor) => {
        const data = { 
            title,
            message,
            flavor,
            navpage: 1
        };
        navigate('/gospel', { 
            state: data
        })
    }

    const handleBackNavigation = () => {
        backgroundRefresh(flavor)
      };

    useEffect(() => {
        if (navType === "POP" && location.key !== "default") {
            handleBackNavigation();
        }
      }, [location]);

    const navigateBack = () => {
        navigate(-1)
    }

  return (
    <>
        <div className="absolute z-20 w-12/12 overflow-hidden">
            <div className="flex flex-col w-screen outline-none focus:outline-none">
                    {/* <div className="relative h-2/5" onClick={() => setShowModal(false)}> */}
                    <div className="relative h-2/5" onClick={() => navigateBack(false)}>
                        <LazyLoad offset={50}>
                        <img src={versionHeader} className="flex w-screen" style={{height: "25vh"}} />
                        </LazyLoad>
                        <h3 className="absolute w-sreen text-3xl text-white text-center top-14 left-1/2 -translate-x-1/2 -translate-y-1/2 w-screen">{version}</h3>
                    </div>
                    <div className='w-full bg-white flex justify-center'>
                        { (flavor === "english" || flavor === "hymns" || flavor === "spanish" || flavor === "french" || flavor === "yoruba")  &&  
                        <input
                                type="text"
                                placeholder="Search..."
                                style={{borderRadius: 100, width: '90vw'}}
                                className="mt-3 p-5 mx-auto focus:ring-0 or focus:ring-transparent border-transparent hover:border-transparent focus:outline-none text-gray-500"
                                onClick={() => {
                                    navigate('/search-hymn', {
                                        state: {
                                            flavor: flavor,
                                            navpage: 1
                                        }
                                    })
                                }}
                            />
                        }
                    </div>
                    <div className="bg-white pl-5 pr-5 pb-5 pb-10 text-sm" style={{height: `52vh`}} >
                    { (flavor === "english" || flavor === "hymns" || flavor === "spanish" || flavor === "french" || flavor === "yoruba")  &&  
                        <button
                            class="bg-red-400"
                            style={{ position: 'absolute', borderRadius:100, cursor: 'text', top: '65%', right: 18, zIndex: 1 }}
                            onClick={() => {
                                navigate('/search-hymn', {
                                    state: {
                                        flavor: flavor,
                                        navpage: 1
                                    }
                                })
                            }}> 
                        <RiSearchEyeLine size="40" className="text-white" />
                    </button>
                    }
                        <ul className="list-none divide-y overflow-y-scroll pb-5 no-scrollbar" style={{height: `50vh`, }}>
                            {
                                flavor === "english" || flavor === "hymns" || flavor === "spanish" || flavor === "french" || flavor === "yoruba" ? 
                                (
                                    hymnDb?.map((item, index)=>(
                                        <li key={item.tno} className="py-3" onClick={() => viewHymn(item?.tno, item?.tname, item?.verse, item?.category, flavor)}>
                                            <div>
                                                <p>Hymn {item.tno}: </p>
                                                <p className="font-bold">{item?.verse.substr(3, 30)}...</p>
                                            </div>
                                        </li>
                                    ))
                                
                                ) : null
                            }

                            {
                                flavor === "author" ? 
                                (
                                    hymnDb?.map((item, index)=>(
                                        <li key={item._id} className="py-3" onClick={() => viewAuthor(item?.author, item?.description, flavor)}>
                                            <div>
                                                <p>{item.author} </p>
                                            </div>
                                        </li>
                                    ))
                                
                                ) : null
                            }

                            {
                                flavor === "gospel" ? 
                                (
                                    hymnDb?.map((item, index)=>(
                                        <li key={item._id} className="py-3" onClick={() => viewGospel(item?.title, item?.message, flavor)}>
                                            <div>
                                                <p>{item.title}: </p>
                                            </div>
                                        </li>
                                    ))
                                
                                ) : null
                            }
                            
                        </ul>
                    </div>
            </div>
        </div>
    </>
  )
}

export default memo(DialogHymn)
