
import React, {useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom';
const isAndroid = /Android/i.test(navigator.userAgent);
const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

const DesktopHymnPicker = ({assets}) => {

    const location = useLocation();
    const navigate = useNavigate();


    const navPage = (category) => {
        if(category=='english'){
            navigate('/hymn-cards', 
            {
                state: {
                    pageTitle: 'ENGLISH HYMN',
                    category: 'english'
                }
            })
        }

        if(category=='yoruba'){
            navigate('/hymn-cards', 
            {
                state: {
                    pageTitle: 'YORUBA HYMN',
                    category: 'yoruba'
                }
            })
        }

        if(category=='spanish'){
            navigate('/hymn-cards', 
            {
                state: {
                    pageTitle: 'SPANISH HYMN',
                    category: 'spanish'
                }
            })
        }

        if(category=='french'){
            navigate('/hymn-cards', 
            {
                state: {
                    pageTitle: 'FRENCH HYMN',
                    category: 'french'
                }
            })
        }
        
    }

    return (
        <div className='h-full md:pl-3 pt-7 no-scrollbar hide-scrollbar'>
            <div className="fexl flex-row">
            {/* text-md font-bold md:w-4/6 lg:w-4/6 w-6/6 sm:pr-3  */}
                <p className="ml-0 px-3 text-md font-bold w-6/6 md:w-4/6 justify-left">And now, dear brothers and sisters, one final thing. Fix your thoughts on what is true, and honorable, and right, and pure, and lovely, and admirable. Think about things that are excellent and worthy of praise. <span className="mt-4 text-sm">-  Phillipians 4:18</span></p>
            </div>

            <div className="w-full flex-container">
                <div className="ml-0 md:w-2/6 w-full flex-item bg-blue-200"
                    onClick={() => navPage("english")}
                    style={{
                    backgroundImage: `url("${assets.englishHeader}")`,
                    backgroundSize:  'cover',
                }} >
                    <p className='text-white lg:text-3xl md:text-2xl text-lg font-bold'>ENGLISH HYMN</p>
                </div>
                <div className="ml-0 md:ml-5 md:w-2/6 w-full flex-item bg-blue-200"
                    onClick={() => navPage("french")}
                    style={{
                    backgroundImage: `url("${assets.frenchHeader}")`,
                    backgroundSize:  'cover',
                }} >
                    <p className='text-white lg:text-3xl md:text-2xl text-lg font-bold'>FRENCH HYMN</p>
                </div>
                <div className="ml-0 md:w-2/6 w-full flex-item bg-blue-200"
                    onClick={() => navPage("spanish")}
                    style={{
                    backgroundImage: `url("${assets.spanishHeader}")`,
                    backgroundSize:  'cover',
                }} >
                    <p className='text-white lg:text-3xl md:text-2xl text-lg font-bold'>SPANISH HYMN</p>
                </div>
                <div className="ml-0 md:ml-5 md:w-2/6 w-full flex-item bg-blue-200"
                    onClick={() => navPage("yoruba")}
                    style={{
                    backgroundImage: `url("${assets.yorubaHeader}")`,
                    backgroundSize:  'cover',
                    backgroundPosition: 'center bottom'
                }} >
                    <p className='text-white lg:text-3xl md:text-2xl text-lg font-bold'>YORUBA HYMN</p>
                </div>
            </div>
        </div>
    )

}

export default DesktopHymnPicker