// import React from 'react'
// // import ReactDOM from 'react-dom'
// import ReactDOM from "react-dom/client";
// import {BrowserRouter as Router, HashRouter} from 'react-router-dom'
// import App from './App'
// import './index.css'
// import ErrorBoundary from './ErrorBoundary';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <ErrorBoundary>
//         <HashRouter>
//             <App />
//         </HashRouter>
//     </ErrorBoundary>
// );


// import React, { useState, useEffect } from "react";
// import ReactDOM from "react-dom/client";
// import { HashRouter } from "react-router-dom";
// import App from "./App";
// import "./index.css";
// import ErrorBoundary from "./ErrorBoundary";

// const LoadingSpinner = () => {
//     return (
//       <div className="spinner-container">
//         <div className="spinner"></div>
//         <div className="loading-text">Please wait...</div>
//       </div>
//     );
//   };

// const Root = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [networkSpeed, setNetworkSpeed] = useState("unknown");

//   return (
//     <ErrorBoundary>
//       <HashRouter>
//         <App />
//       </HashRouter>
//     </ErrorBoundary>
//   );

// };

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(<Root />);


import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import ErrorBoundary from "./ErrorBoundary";

// Loading Spinner Component
const LoadingSpinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
      <div className="loading-text">Loading...</div>
    </div>
  );
};

// Main App Wrapper with Loading Screen Logic
const Root = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [networkSpeed, setNetworkSpeed] = useState("unknown");
  const [isFirstVisit, setIsFirstVisit] = useState(false);

  useEffect(() => {

    const checkFirstVisit = () => {
        const hasVisited = localStorage.getItem("pageLoadedFirstTime");
        if (!hasVisited) {
          setIsFirstVisit(true);
          localStorage.setItem("pageLoadedFirstTime", "true");
        } else {
          setIsLoading(false); // Skip loading if not the first visit
        }
      };

    // Determine network speed using the Network Information API
    const getNetworkSpeed = () => {
      if (navigator.connection) {
        const downlink = navigator.connection.downlink || 1; // default to 1 if not available
        setNetworkSpeed(downlink);
      } else {
        setNetworkSpeed("unknown");
      }
    };

    // Adjust loading time based on network speed
    const adjustLoadingTime = () => {
      let loadTime = 1000; // default to 1 second
      if (networkSpeed === "unknown" || networkSpeed <= 0.5) {
        loadTime = 30000; // very slow connection (e.g., 2G)
      } else if (networkSpeed > 0.5 && networkSpeed <= 1) {
        loadTime = 25000; // 3G (set to 30 seconds)
      } else if (networkSpeed > 1 && networkSpeed <= 3) {
        loadTime = 6000; // 4G (set to 6 seconds)
      } else if (networkSpeed > 3) {
        loadTime = 1000; // fast connection (e.g., 5G or higher)
      }
      return loadTime;
    };

    const initializeLoading = () => {
        if (isFirstVisit) {
          const timeout = setTimeout(() => {
            setIsLoading(false);
          }, adjustLoadingTime());
  
          return () => clearTimeout(timeout);
        }
      };

    // Simulate loading time based on network speed
    // const timeout = setTimeout(() => {
    //   setIsLoading(false);
    // }, adjustLoadingTime());

    // Update network speed on page load or network change
    checkFirstVisit();
    getNetworkSpeed();
    initializeLoading();
    window.addEventListener("online", getNetworkSpeed);
    window.addEventListener("offline", getNetworkSpeed);

    return () => {
    //   clearTimeout(timeout);
      window.removeEventListener("online", getNetworkSpeed);
      window.removeEventListener("offline", getNetworkSpeed);
    };
  }, [networkSpeed, isFirstVisit]);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <ErrorBoundary>
      <HashRouter>
        <App />
      </HashRouter>
    </ErrorBoundary>
  );
};

// Render to the DOM
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
